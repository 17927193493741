


















































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'
import { EventbusType, IEventbus } from '@movecloser/front-core'

import {
  CartItemData,
  CartTax,
  ISiteService,
  OrderData,
  PaymentMethodCode,
  SiteServiceType
} from '../../../contexts'
import { Inject, logger } from '../../../support'
import { StructureConfigurable } from '../../../support/mixins'

import { DrawerType, IDrawer } from '../../shared/contracts/services'

import { AuthMixin, openAuthDrawer } from '../../auth/shared'
import { AuthDrawerTab } from '../../auth/organisms/AuthDrawer/AuthDrawer.config'

import { ORDER_KEY } from '../services/cart'
import { THANK_YOU_COMPONENT_KEY, THANK_YOU_DEFAULT_CONFIG } from './views.config'
import { RouteNames } from '../../auth/routes'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component<ThankYouView>({
  name: 'ThankYouView',
  created (): void {
    this.config = this.getComponentConfig(THANK_YOU_COMPONENT_KEY, { ...THANK_YOU_DEFAULT_CONFIG })
  },
  mounted (): void {
    this.isLoading = this.isWaitingForAuth
    this.loadOrder()
    this.purchaseEvent()
  }
})
export class ThankYouView extends Mixins(AuthMixin, StructureConfigurable) {
  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  protected readonly siteService!: ISiteService

  public isLoading: boolean = true
  public PaymentMethodCode = PaymentMethodCode
  public order: OrderData | null = null

  public get backLink (): RawLocation {
    return '/'
  }

  public get createAccountStrategy (): string {
    return this.getConfigProperty<string>('createAccountStrategy')
  }

  public get instructions (): string[][] {
    if (!this.order?.instructions) {
      return []
    }

    const input = this.order?.instructions.split('\r\n').filter(line => !!line)
    const instructions = []

    for (let i = 0; i < input.length; i += 2) {
      instructions.push([input[i], input[i + 1]])
    }

    return instructions
  }

  public get loggedInText (): string {
    const text = this.$t('front.checkout.views.ThankYouView.loggedIn.text').toString()

    return (text === 'front.checkout.views.ThankYouView.loggedIn.text') ? '' : text
  }

  public get orderNumber (): string {
    if (!this.order) {
      return ''
    }

    return `Zam. nr #${this.order.orderNumber}`
  }

  public get registerLink (): RawLocation {
    return { name: 'profile.me' }
  }

  public get thankYouText () {
    return {
      orderNumber: this.order?.orderNumber,
      from: this.order?.deliveryFrom,
      to: this.order?.deliveryTo
    }
  }

  public copy (name: string, index?: number): void {
    const ref = this.$refs[name] as HTMLInputElement | HTMLInputElement[]
    let toCopy: HTMLInputElement

    if (!ref) {
      return
    }

    if (index !== undefined) {
      toCopy = (ref as HTMLInputElement[])[index]
    } else {
      toCopy = ref as HTMLInputElement
    }

    toCopy.focus()
    document.execCommand('copy')
  }

  public loadOrder (): void {
    try {
      const orderData = localStorage.getItem(ORDER_KEY)

      if (!orderData) {
        throw new Error('Missing order data in localStorage')
      }

      this.order = JSON.parse(orderData)
    } catch (e) {
      logger(e)
    }
  }

  public register (): void {
    if (this.createAccountStrategy === 'router') {
      this.$router.push({ name: `auth.${RouteNames.Auth}` })
      return
    }

    if (this.createAccountStrategy === 'drawer' && this.drawerConnector) {
      openAuthDrawer(this.drawerConnector, { activeTab: AuthDrawerTab.Signup })
    }
  }

  @Watch('isWaitingForAuth')
  public setLoading () {
    this.isLoading = false
  }

  protected purchaseEvent (): void {
    const getFirstVariant = (item: CartItemData) => {
      return Object.values(item.product.variants)[0]
    }
    const getFirstVariantKey = (item: CartItemData) => {
      const key = Object.keys(item.product.variants)[0] ?? ''
      return (key !== '_') ? key : ''
    }
    let tax
    if (this.order && this.order?.cart.hasOwnProperty('taxes')) {
      tax = this.order.cart.taxes.reduce((acc: number, tax: CartTax) => {
        return acc + tax.amount.value
      }, 0)
    }

    this.eventBus.emit('app:checkout.purchase', {
      value: this.order?.cart.total.value,
      items: this.order?.cart.items.map((item: CartItemData) => {
        const variant = getFirstVariant(item)
        const variantSlug = getFirstVariantKey(item)
        return {
          id: variant.sku,
          parent_id: item.product.sku,
          variant: variantSlug,
          name: variant.name,
          currency: this.order?.cart.total.currency,
          category: variant.attributes.mainCategory,
          brand: variant.attributes.brand,
          price: variant.price.finalPrice,
          quantity: item.quantity,
          url:
            this.siteService.getActiveSiteAddress() + `${item.product.urlPath}`,
          image: item.product.variants && (Array.isArray(variant.images) &&
            variant.images.length > 0)
            ? this.siteService.getActiveSiteAddress() +
            variant.images[0].url : ''
        }
      }),
      transactionId: this.order ? this.order.orderNumber : '',
      coupon: (this.order?.cart.coupons[0]) ? this.order?.cart.coupons[0].code : '',
      shipping: this.order?.cart.selectedShippingMethod?.price.value,
      tax,
      discount: (this.order?.cart.discounts || []).reduce((sum, discount) => {
        return sum + discount.amount.value
      }, 0),
      customer: this.order?.cart.email ? {
        id: this.order?.cart.email
      } : undefined
    })
  }
}

export default ThankYouView
